.browse {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    /* align-items: center; */
    background-color: white;
    margin-top: 25px;
    padding-left: 25px;
    padding-top: 25px;
}

.word-list {
    display: grid;
    grid-template-columns: auto auto;
    /* color: black; */
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 20px;
    padding-bottom : 20px;
    font-size: 1.3em;
}

.link {
    color: black;
    
}

.pages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    font-size: 1.1em;
}

.alphabets {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction : column; */
    justify-content: center;
    gap: 9px;
    font-weight: bold;
    font-size: 1.2em;
}

@media screen and (max-width:500px) {
    .word-list {
        grid-template-columns: auto;
    /* color: black; */
    }
}