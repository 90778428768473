.footer {
  width: 100%;
  background-color: grey;
  color: white;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}