.searchContainer {
    /* width: 100vw; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* margin: 10px 0; */
    background-color: teal;
    /* padding: 10px 0; */
}
#search-box {
    background-color: white;
}

#search-btn {
    background-color: white;
    color: teal;
    margin-left: 20px;
}