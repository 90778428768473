.spelling-container {
    padding:10px 10px 10px var(--my-margin-left-right);
    background-color: white;
    margin: 12px 0 12px 0;
    
}

.spelling-container a {
    text-align: center;
    size: 30px;
    font-size: 30px;
    padding-bottom: 10px;
    color: rgb(19, 19, 129);
    /* text-decoration: underline; */
}

.spelling-container p {
    color : black;
    padding: 4px;
    /* text-decoration: underline; */
    font-size: 22px;
}

.spellings {
    display: grid;
    grid-template-columns: auto auto;
}