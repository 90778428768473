.container {
  /* margin : 20px 0; */
  display: flex;
  /* padding-bottom: 30px; */
  flex-direction: column;
  background-color: white;
  /* padding: 20px 10px 20px var(--my-margin-left-right); */
  /* padding: auto; */
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  padding-left: 25px;
  padding-top: 25px;
  margin-top: 12px;
  padding-bottom: 22px;
}

.synonyms a {
  color: green; 
  font-weight: bold;
  text-decoration: underline;
  margin-right: 10px;
  font-size: 1.1rem;
}

.header3 {
  margin-top: 20px;
  margin-bottom: 8px;
}

.antonyms a {
  color: red;
  font-weight: bold;
  text-decoration: underline;
  margin-right: 10px;
  font-size: 1.1rem;
}

.word-meaning {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 30px;
}

@media screen and (max-width: 800px) {
  .word-meaning {
    flex-direction: column;
    gap: 0px;
  }
}

#main-word {
  font-size: 3em;
}

#pron {
  font-size: 1.2em;
}

#meaning {
  font-size: 2em;
  padding: 10px 0;
}
