* {
  margin: 0;
  padding : 0;
}

.App {
  background-color: #f5f5f5;
}

ul, li {
  list-style-type: none;
}
