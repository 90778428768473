.notfound-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding-left: 25px;
    padding-top: 25px;
    margin-top: 12px;
    padding-bottom: 22px;
}

.notfound-container p {
    padding-right: 20px;
    text-align: justify;
    font-size: 30px;
    /* word-wrap: break-word; */
}