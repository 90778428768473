.converter-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.converter-title {
  margin-bottom: 20px;
}

.converted-date p {
  font-size: 1.1em;
  margin-top: 8px;
}

.radio-group {
  margin-bottom: 20px;
}

.radio-label {
  margin-right: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.radio-input-1{
  margin-right: 10px;
}

.date-selectors {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.select-input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 120px;
  font-size: 1.5em;
}

.converted-date {
  margin: 20px 0;
  padding: 15px;
  background-color: #f0f7ff;
  border-radius: 4px;
}

.calendar-container {
  margin-top: 20px;
}

.calendar-header-main {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 2px;
  margin-top: 10px;
}

.calendar-header {
  background-color: #f5f5f5;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.calendar-day {
  position: relative;
  height: 80px;  /* Make boxes bigger */
  border: 1px solid #eee;
  padding: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.main-day {
  font-size: 32px;  /* Make main day bigger */
  font-weight: bold;
  margin-top: 8px;
}

.converted-day {
  position: absolute;
  bottom: 4px;
  right: 4px;
  font-size: 16px;
  padding-right: 4px;
  color: #181818;
}

.calendar-day.selected {
  background-color: #e3f2fd;
}

.calendar-day:hover:not(:empty) {
  background-color: #f5f5f5;
  cursor: pointer;
}
