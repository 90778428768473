nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.3em;
    gap: 30px;
}

.home {
    /* padding: 10px; */
    color: black;
    background-color: teal;
}
.website-name {
    color: white;
    display: inline-block;
    padding: 10px;
    /* text-decoration: underline; */
}