.banner-container {
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(to right, #3b82f6, #8b5cf6);
    color: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 50;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .banner-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .banner-logo {
    margin-right: 1rem;
    flex-shrink: 0;
  }
  
  .banner-logo-img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 0.5rem;
  }
  
  .banner-info {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .banner-text {
    margin-right: 1rem;
  }
  
  .banner-title {
    font-weight: bold;
    font-size: 1.125rem;
  }
  
  .banner-description {
    font-size: 0.875rem;
    opacity: 0.8;
  }
  
  .banner-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: bold;
  }
  
  .banner-download-btn {
    background-color: white;
    color: #3b82f6;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: background-color 0.2s;
  }
  
  .banner-download-btn:hover {
    background-color: #f0f9ff;
  }
  
  .banner-icon {
    margin-left: 0.5rem;
  }
  
  .banner-close-btn {
    color: white;
    background: transparent;
    border: none;
    border-radius: 9999px;
    padding: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .banner-close-btn:hover {
    background-color: rgba(255,255,255,0.2);
  }