.download {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  max-width: 800px;
  margin-top: 12px;
  padding-top: 14px;
  background-color: white;
  padding-left: 25px;
  padding-top: 25px;
}

.download p {
  padding-right: 25px;
}

.download-imgs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.ios_a {
  display: inline-block;
  overflow: hidden;
  border-radius: 13px;
  height: 75px;
}

.ios_img {
  border-radius: 13px;
  height: 80px;
}

@media only screen and (max-width: 500px) {
  .download-imgs {
    flex-direction: column;
  }
}
