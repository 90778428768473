.wotd {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    max-width: 800px;
    margin-top: 12px;
    padding-top: 14px;
    background-color: white;
    padding-left: 25px;
    padding-top: 25px;

}

.align-item-end {
    align-items: flex-end;
}

#wotd-word {
    color: teal;
    padding: 20px 0;
    text-decoration: underline;
}